import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import * as CookieConsent from "vanilla-cookieconsent";
import CookiesConfiguration from "./cookieconsent-config";
import { ErrorBoundary } from "react-error-boundary";
import { router } from './router';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import configuration from './config/config';
import ErrorBoundaryFallback from './components/Feedback/ErrorBoundaryFallback';
import { ApplicationProvider } from './store/application/context';

import "./colors.css";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import "vanilla-cookieconsent/dist/cookieconsent.css";

if (configuration.integrations?.googleAnalyticsApiKey) {
  ReactGA.initialize(configuration.integrations?.googleAnalyticsApiKey);
}

export const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    CookieConsent.run(CookiesConfiguration);
  }, []);

  document.documentElement.lang = i18n.resolvedLanguage || i18n.language;

  return (
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <ApplicationProvider>
          <RouterProvider router={router} />
        </ApplicationProvider>
        <ToastContainer
          toastClassName={"relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"}
          bodyClassName={() => "text-sm font-white font-med block p-3"}
          position="bottom-right"
          autoClose={3000} />
      </ErrorBoundary>
    </React.StrictMode>
  );
};
