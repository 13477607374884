import clsx from 'clsx'
import LocalizedLink from './Router/LocalizedLink'

const baseStyles = {
  solid:
    'group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    'group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none',
}

const variantStyles = {
  solid: {
    slate:
      'bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900',
    primary: 'bg-primary-700 text-white hover:text-slate-100 hover:bg-primary-600 active:bg-primary-900 active:text-primary-200 focus-visible:outline-primary-700',
    secondary: 'bg-secondary-600 text-white hover:text-slate-100 hover:bg-secondary-500 active:bg-secondary-800 active:text-secondary-100 focus-visible:outline-secondary-600',
    third: 'bg-primary-500 text-white hover:text-slate-100 hover:bg-primary-400 active:bg-primary-700 active:text-primary-050 focus-visible:outline-primary-500',
    white:
      'bg-white text-slate-900 hover:bg-primary-50 active:bg-primary-200 active:text-slate-600 focus-visible:outline-white',
  },
  outline: {
    slate:
      'ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-primary-600 focus-visible:ring-slate-300',
    white:
      'ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white',
  },
}

type ButtonProps = (
  | {
    variant?: 'solid'
    color?: keyof typeof variantStyles.solid
  }
  | {
    variant: 'outline'
    color?: keyof typeof variantStyles.outline
  }
) &
  (
    | Omit<React.ComponentPropsWithoutRef<typeof LocalizedLink>, 'color'>
    | (Omit<React.ComponentPropsWithoutRef<'button'>, 'color'> & {
      to?: undefined
    })
  )

export function Button({ className, ...props }: ButtonProps) {
  props.variant ??= 'solid'
  props.color ??= 'slate'

  className = clsx(
    baseStyles[props.variant],
    props.variant === 'outline'
      ? variantStyles.outline[props.color]
      : props.variant === 'solid'
        ? variantStyles.solid[props.color]
        : undefined,
    className,
    " transition-all ease-in-out duration-300"
  )

  if (typeof props.to !== 'undefined') {
    return <LocalizedLink className={className} {...props} />;
  } else {
    return <button className={className} {...props} />;
  }
}
