import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'

import Data from 'src/data/Companies.json';

export interface Company {
  name: string;
  logo: string;
  link: string;
}

export const Companies = () => {
  const data = Data as Company[];
  return (
    <div className="mt-32 lg:mt-40">
      <p className="font-display text-base text-slate-900">
        Zaufało nam już wielu klientów, przekonaj się i Ty jak łatwo zaistnieć w internecie!
      </p>
      <ul
        role="list"
        className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
      >
        <li>
          <ul
            role="list"
            className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
          >
            {data.map((company) => (
              <a href={company.link} target='_blank'>
                <li key={company.name} className="flex">
                  <img src={company.logo} alt={company.name} className="h-12 grayscale hover:grayscale-0 transition-all ease-in-out delay-100 duration-300" />
                </li>
              </a>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  )
}

export function Hero() {
  return (
    <div className="relative text-center -mt-20 py-32 min-h-svh flex items-center justify-center">
      <img
        className="absolute left-0 top-0 w-full h-full object-cover grayscale opacity-20 pointer-events-none"
        src="https://as1.ftcdn.net/v2/jpg/05/70/12/74/1000_F_570127421_9NDxQHXLgYSUHjQJDxXrRbySAETZDDuE.jpg"
        alt="Background"
        width={2347}
        height={1244}
      />
      <Container>
        <div className="relative z-10 lg:mt-10 xl:mt-16">
          <h1 className="mx-auto max-w-4xl text-5xl font-medium font-branding tracking-tight text-slate-900 sm:text-7xl">
            Puść{' '}
            <span className="relative whitespace-nowrap text-secondary-500 tracking-tight">
              wodze
            </span>
            {' '}fantazji <span className="whitespace-nowrap">i zaprojektuj</span>
            <br />
            wymarzoną stronę dla{' '}
            <span className="relative whitespace-nowrap text-primary-700 tracking-tight">
              <span className="relative">Twojej stajni</span>
            </span>
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
            Więcej klientów i mniej stresu dzięki niezawodnemu systemowi zarządzania ośrodkiem jeździeckim w zasięgu Twojej ręki.
          </p>
          <div className="mt-4 lg:mt-8 xl:mt-10 flex flex-col md:flex-row justify-center gap-y-6 md:gap-y-0 md:gap-x-6">
            <Button color="secondary" to="/apply">Spróbuj za darmo przez miesiąc</Button>
            <Button
              to="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              variant="outline"
            >
              <svg
                aria-hidden="true"
                className="h-3 w-3 flex-none fill-secondary-500 group-active:fill-current "
              >
                <path d="m9.997 6.91-7.583 3.447A1 1 0 0 1 1 9.447V2.553a1 1 0 0 1 1.414-.91L9.997 5.09c.782.355.782 1.465 0 1.82Z" />
              </svg>
              <span className="ml-3">Obejrzyj prezentację</span>
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}
