import { useId } from 'react'
import clsx from 'clsx'

const formClasses =
  'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-primary-500 focus:bg-white focus:outline-none focus:ring-primary-500 sm:text-sm'

function Label({ id, children }: { id: string; children: React.ReactNode }) {
  return (
    <label
      htmlFor={id}
      className="mb-3 block text-sm font-medium text-gray-700"
    >
      {children}
    </label>
  )
}

export function TextAreaField({
  label,
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'textarea'>, 'id'> & { label: string }) {
  const id = useId()

  return (
    <div className={className}>
      {label && <Label id={id}>{label} {props.required && <span className="text-red-700">*</span>}</Label>}
      <textarea id={id} {...props} className={formClasses} />
    </div >
  )
}

export function TextField({
  label,
  suffix,
  type = 'text',
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label: string, suffix?: string }) {
  const id = useId()

  return (
    <div className={className}>
      {label && <Label id={id}>{label} {props.required && <span className="text-red-700">*</span>}</Label>}

      {!suffix && <input id={id} type={type} {...props} className={formClasses} />}
      {suffix && <div className={formClasses + ' flex'}>
        <input id={id} type={type} {...props} className="appearance-none bg-transparent border-none outline-none focus:outline-none ring-none border-transparent focus:border-transparent focus:ring-0 text-sm p-0 m-0 flex-1" />
        <span>{suffix}</span>
      </div>}
    </div>
  )
}

export function SelectField({
  label,
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'select'>, 'id'> & { label: string }) {
  const id = useId()

  return (
    <div className={className}>
      {label && <Label id={id}>{label} {props.required && <span className="text-red-700">*</span>}</Label>}
      <select id={id} {...props} className={clsx(formClasses, 'pr-8')} />
    </div>
  )
}
