import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'

export function CallToAction() {
  return (
    <section
      id="get-started-today"
      className="relative overflow-hidden bg-primary-600 w-svh xl:w-auto py-32 xl:py-64"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src="/images/background.webp"
        alt="Background"
        width={2347}
        height={1244}
      />
      <div className="absolute inset-0 bg-primary-900 opacity-90"></div>
      <Container className="relative flex items-center h-full">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-4xl sm:text-5xl tracking-tight text-white">
            Zacznij już dzisiaj
          </h2>
          <p className="mt-8 text-xl tracking-tight text-white">
            Zwiększ efektywność swojego ośrodka jeździeckiego<br />i zacznij zarządzać nim w prosty i intuicyjny sposób.
          </p>
          <Button to="/apply" color="secondary" className="mt-16">
            Wypróbuj za darmo przez miesiąc
          </Button>
        </div>
      </Container>
    </section>
  )
}
