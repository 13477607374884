import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { CallToAction } from 'src/components/CallToAction'
import { ContactForm } from 'src/components/ContactForm'
import { Faqs } from 'src/components/Faqs'
import { Hero } from 'src/components/Hero'
import { MobileBanner } from 'src/components/MobileBanner'
import { Pricing } from 'src/components/Pricing'
import { PrimaryFeatures } from 'src/components/PrimaryFeatures'
import { ReferralProgram } from 'src/components/ReferralProgram'
import { SecondaryFeaturesNew } from 'src/components/SecondaryFeaturesNew'
import { Testimonials } from 'src/components/Testimonials'

export default function Index() {
  const { hash } = useLocation();
  useEffect(() => { if (!hash) window.scrollTo(0, 0) }, [hash]);

  return (
    <>
      <Hero />
      <PrimaryFeatures />
      <SecondaryFeaturesNew />
      <MobileBanner />
      <CallToAction />
      <ReferralProgram />
      <Pricing />
      <Faqs />
      <ContactForm />
    </>
  )
}
