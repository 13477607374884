import { useEffect, useRef, useState } from "react";

export function mod(n: number, max: number) {
  return ((n % max) + max) % max;
}

const useAutoPlay = (max: number, timeout?: number): {
  start: () => void;
  stop: () => void;
  next: () => void;
  prev: () => void;
  setCurrentIndex: (i: number) => void;
  currentIndex: number;
} => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const refCurrentIndex = useRef(0);
  const [isAutoplay, setAutoplay] = useState(true);
  const autoPlayTimer = useRef<NodeJS.Timeout>();

  const setCurrentIndexEx = (i: number) => {
    setCurrentIndex(mod(i, max));
  }

  const start = () => {
    setAutoplay(true);
  }

  const stop = () => {
    setAutoplay(false);
  }

  const next = () => {
    setCurrentIndexEx(currentIndex + 1);
    stop();
  }

  const prev = () => {
    setCurrentIndexEx(currentIndex - 1);
    stop();
  }

  const autoPlay = () => {
    setCurrentIndexEx(refCurrentIndex.current + 1);
    autoPlayTimer.current = setTimeout(autoPlay, timeout ?? 1000);
  }

  useEffect(() => {
    if (isAutoplay) {
      if (!autoPlayTimer.current) {
        autoPlayTimer.current = setTimeout(autoPlay, timeout ?? 1000);
      }
    } else {
      if (autoPlayTimer.current) {
        clearTimeout(autoPlayTimer.current);
        autoPlayTimer.current = undefined;
      }
    }
  }, [isAutoplay, autoPlayTimer.current]);

  useEffect(() => {
    refCurrentIndex.current = currentIndex;
  }, [currentIndex]);

  return {
    start,
    stop,
    next,
    prev,
    setCurrentIndex: setCurrentIndexEx,
    currentIndex,
  }
}

export default useAutoPlay;