/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import {
  Popover,
  PopoverButton,
  PopoverBackdrop,
  PopoverPanel,
} from '@headlessui/react'
import clsx from 'clsx'

import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'
import { faKey, faPhone } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocalizedLink from './Router/LocalizedLink'


function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <PopoverButton
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }: any) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <PopoverBackdrop
        transition
        className="fixed inset-0 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <PopoverPanel
        transition
        className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <LocalizedLink to="/#features">Funkcjonalności</LocalizedLink>
        <LocalizedLink to="/#pricing">Ceny</LocalizedLink>
        <LocalizedLink to="/#contact">Kontakt</LocalizedLink>
        <hr className="m-2 border-slate-300/40" />
        <LocalizedLink to="/auth/sign-in">Zaloguj się</LocalizedLink>
      </PopoverPanel>
    </Popover>
  )
}

export function Header() {
  return (
    <>
      <header className="py-3 fixed top-0 start-0 left-0 bg-white w-full z-50">
        <Container>
          <nav className="relative z-50 flex justify-between">
            <div className="flex items-center md:gap-x-12">
              <LocalizedLink to="/" aria-label="Home" className="flex" onClick={() => window.scrollTo(0, 0)}>
                <img src="/images/logos/logo-full-color-transparent.webp" className="relative h-12 w-auto" alt="Redini Logo" />
              </LocalizedLink>
              <div className="hidden md:flex md:gap-x-6">
                <LocalizedLink to="/#features">Funkcjonalności</LocalizedLink>
                <LocalizedLink to="/#pricing">Ceny</LocalizedLink>
                <LocalizedLink to="/#contact">Kontakt</LocalizedLink>
              </div>
            </div>
            <div className="flex items-center gap-x-5 md:gap-x-8">
              <div className="hidden md:block">
                <LocalizedLink to="/auth/sign-in">
                  <FontAwesomeIcon icon={faKey} className="mr-2" />
                  <span className='md:hidden lg:inline'>Zaloguj się</span>
                </LocalizedLink>
              </div>
              <a href="tel:48585008438">
                <Button color="secondary">
                  <FontAwesomeIcon icon={faPhone} className="mr-2" />
                  <span>
                    58 500 84 38
                  </span>
                </Button>
              </a>
              <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div>
            </div>
          </nav>
        </Container>
      </header>
      <div className="mt-20"></div>
    </>
  )
}
