import clsx from 'clsx'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'
import PlansData from 'src/data/Plans.json';
import ModulesData from 'src/data/Modules.json';
import useEntityTranslation from 'src/hooks/useEntityTranslation'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimes } from '@fortawesome/pro-duotone-svg-icons'

function SwirlyDoodle(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 281 40"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z"
      />
    </svg>
  )
}

function CheckIcon({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      aria-hidden="true"
      className={clsx(
        'h-6 w-6 flex-none fill-current stroke-current',
        className,
      )}
      {...props}
    >
      <path
        d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
        strokeWidth={0}
      />
      <circle
        cx={12}
        cy={12}
        r={8.25}
        fill="none"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export interface PricePlan {
  key: string
  price?: number
  href: string
  featured?: boolean
  annually?: boolean
  disabled?: boolean
  translations: PricePlanTranslation[]
  priceAnnually?: number
  active?: boolean
  onClick?: () => void
}

export interface PricePlanTranslation {
  culture: string
  name: string
  description: string
  features: string[]
  additional?: string
}

export interface PriceModule {
  key: string
  price?: number
  href: string
  featured?: boolean
  annually?: boolean
  disabled?: boolean
  translations: PriceModuleTranslation[]
  priceAnnually?: number
  active?: boolean
  onClick?: () => void
}

export interface PriceModuleTranslation {
  culture: string
  name: string
  description: string
  features: string[]
  additional?: string
}

function Plan(plan: PricePlan) {
  const { i18n } = useTranslation();
  const pricePlanTranslation = useEntityTranslation<PricePlan, PricePlanTranslation>();
  if (plan.disabled) {
    return <></>;
  }
  return (
    <section
      className={clsx(
        'flex flex-col rounded-3xl px-6 sm:px-8 cursor-pointer',
        plan.featured ? 'bg-primary-500 py-8' : 'lg:py-8',
        plan.active ? 'ring-2 ring-white' : 'hover:ring-2 hover:ring-white/50 active:ring-white/75',
      )}
      onClick={plan.onClick}
    >
      <h3 className="mt-5 font-display text-lg text-white">{pricePlanTranslation.getCurrentTranslation(plan)?.name}</h3>
      {plan.price && plan.priceAnnually ?
        <>
          {!plan.annually &&
            <div>
              <p className="order-first font-display text-5xl font-light tracking-tight text-white">
                {plan.price} <span className="text-3xl">PLN</span>
                <span className="text-xl ml-3">miesięcznie</span>
              </p>
              <p className="order-first font-display text-xs font-light tracking-tight text-white">
                {(plan.price / 30).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN dziennie
              </p>
            </div>
          }
          {plan.annually &&
            <div>
              <p className="order-first font-display text-5xl font-light tracking-tight text-white">
                {plan.priceAnnually} <span className="text-3xl">PLN</span>
                <span className="text-xl ml-3">rocznie</span>
              </p>
              <p className="order-first font-display text-xs font-light tracking-tight text-white">
                oszczędzasz {(plan.price * 12 - plan.priceAnnually).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN rocznie przy rocznej płatności
              </p>
              <p className="order-first font-display text-xs font-light tracking-tight text-white">
                {(plan.priceAnnually / 365).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN dziennie
              </p>
            </div>
          }
        </> :
        <>
          <div>
            <p className="order-first font-display text-4xl font-light tracking-tight text-white 2xl:mt-8 mt-4">
              Oferta indywidualna
              <span className="text-xl ml-3"></span>
            </p>
          </div>
        </>
      }
      {!plan.disabled && !plan.price &&
        <Button
          to={plan.href}
          variant={plan.featured ? 'solid' : 'outline'}
          color="white"
          className="2xl:mt-12 mt-8"
          aria-label={`Get started with the ${name} plan for ${plan.price}`}
        >
          Jestem zainteresowany!
        </Button>
      }
      {!plan.disabled && plan.price &&
        <Button
          to={undefined}
          variant={plan.featured ? 'solid' : 'outline'}
          color="white"
          className="mt-8"
          aria-label={`Get started with the ${name} plan for ${plan.price}`}
        >
          Wybierz ten plan
        </Button>
      }
      {plan.disabled &&

        <span
          className="mt-8 text-gray-500 text-sm p-2 text-center w-full"
          aria-label={`Get started with the ${name} plan for ${plan.price}`}
        >
          Dostępne wkrótce
        </span>
      }
      <p
        className={clsx(
          'mt-6 text-base',
          plan.featured ? 'text-white' : 'text-slate-400',
        )}
      >
        {pricePlanTranslation.getCurrentTranslation(plan)?.description}
      </p>
      <ul
        role="list"
        className={clsx(
          'order-last mt-10 flex flex-col gap-y-3 text-sm',
          plan.featured ? 'text-white' : 'text-slate-200',
        )}
      >
        {pricePlanTranslation.getCurrentTranslation(plan)?.features.map((feature) => (
          <li key={feature} className="flex">
            <CheckIcon className={plan.featured ? 'text-white' : 'text-primary-500'} />
            <span className="ml-4">{feature}</span>
          </li>
        ))}
      </ul>
      <div className="text-xs text-slate-500 order-last pt-3">{pricePlanTranslation.getCurrentTranslation(plan)?.additional}</div>
    </section>
  )
}

function Module(module: PriceModule) {
  const { i18n } = useTranslation();
  const priceModuleTranslation = useEntityTranslation<PriceModule, PriceModuleTranslation>();
  if (module.disabled) {
    return <></>;
  }
  return (
    <section
      className={clsx(
        'w-full md:w-1/2 lg:w-1/3 xl:w-1/4 flex-col rounded-3xl px-6 sm:px-8 cursor-pointer',
        module.featured ? 'bg-primary-500 py-8' : 'lg:py-8',
        module.active ? 'ring-2 ring-white' : 'ring-2 ring-white/10 hover:ring-white/50 active:ring-white/75',
      )}
      onClick={module.onClick}
    >
      <h3 className="mt-5 font-display text-white">{priceModuleTranslation.getCurrentTranslation(module)?.name}</h3>
      {module.price && module.priceAnnually ?
        <>
          {!module.annually &&
            <div>
              <p className="order-first font-display text-3xl font-light tracking-tight text-white">
                {module.price} <span className="text-xl">PLN</span>
                <span className="text-lg ml-3">miesięcznie</span>
              </p>
              <p className="order-first font-display text-xs font-light tracking-tight text-white">
                {(module.price / 30).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN dziennie
              </p>
            </div>
          }
          {module.annually &&
            <div>
              <p className="order-first font-display text-3xl font-light tracking-tight text-white">
                {module.priceAnnually} <span className="text-xl">PLN</span>
                <span className="text-xl ml-3">rocznie</span>
              </p>
              <p className="order-first font-display text-xs font-light tracking-tight text-white">
                oszczędzasz {(module.price * 12 - module.priceAnnually).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN rocznie przy rocznej płatności
              </p>
              <p className="order-first font-display text-xs font-light tracking-tight text-white">
                {(module.priceAnnually / 365).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN dziennie
              </p>
            </div>
          }
        </> :
        <>
          <div>
            <p className="order-first font-display text-4xl font-light tracking-tight text-white">
              Oferta indywidualna
              <span className="text-xl ml-3"></span>
            </p>
          </div>
        </>
      }
      {!module.disabled &&
        <Button
          to={undefined}
          variant={module.featured ? 'solid' : 'outline'}
          color="white"
          className="mt-8 w-full"
          aria-label={`Get started with the ${name} plan for ${module.price}`}
        >
          Dodaj ten moduł
        </Button>
      }
      {module.disabled &&

        <span
          className="mt-8 text-gray-500 text-sm p-2 text-center w-full"
          aria-label={`Get started with the ${name} plan for ${module.price}`}
        >
          Dostępne wkrótce
        </span>
      }
      <p
        className={clsx(
          'mt-6 text-sm',
          module.featured ? 'text-white' : 'text-slate-400',
        )}
      >
        {priceModuleTranslation.getCurrentTranslation(module)?.description}
      </p>
      <ul
        role="list"
        className={clsx(
          'order-last mt-5 flex flex-col gap-y-3 text-xs',
          module.featured ? 'text-white' : 'text-slate-200',
        )}
      >
        {priceModuleTranslation.getCurrentTranslation(module)?.features.map((feature) => (
          <li key={feature} className="flex">
            <FontAwesomeIcon icon={faCheckCircle} className={clsx(["relative top-0.5", module.featured ? "" : "text-gray-400"])} />
            <span className="ml-2">{feature}</span>
          </li>
        ))}
      </ul>
      <div className="text-xs text-primary-500 order-last pt-3">{priceModuleTranslation.getCurrentTranslation(module)?.additional}</div>
    </section>
  )
}

interface PricingBannerProps {
  chosedPlan?: PricePlan;
  chosedModules: PriceModule[];
  annually: boolean;
  onClose: () => void;
  disableModule: (key: string) => void;
}

const PricingBanner = (props: PricingBannerProps) => {
  const { chosedPlan, chosedModules, annually, onClose, disableModule } = props;
  const { i18n } = useTranslation();

  const planTranslation = useEntityTranslation<PricePlan, PricePlanTranslation>();
  const moduleTranslation = useEntityTranslation<PriceModule, PriceModuleTranslation>();

  const summary = chosedPlan ? (annually ? chosedPlan.priceAnnually! + _.sum(chosedModules.map(m => m.priceAnnually)) : chosedPlan.price! + _.sum(chosedModules.map(m => m.price))) : 0;

  return (
    <div className={clsx(['fixed left-0 bottom-0 z-50 w-full p-4 transition-all duration-500 delay-100 ', chosedPlan ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'])}>
      <div className="overflow-y-auto inline-block w-auto max-w-full mr-auto bg-secondary-500 text-white rounded-2xl shadow-xl shadow-slate-950/25">
        <div className="flex mx-5 my-3 mr-10">
          <div className="border border-white rounded-lg h-32 w-32 text-sm text-center p-4 mr-4 pointer-events-auto">
            <div>Pakiet</div>
            <div className="font-medium">{planTranslation.getCurrentTranslation(chosedPlan)?.name}</div>
            <div>
              {annually ? `${(chosedPlan?.priceAnnually || 0).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN rocznie` : `${(chosedPlan?.price || 0).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN miesięcznie`}
            </div>
          </div>
          {chosedModules.map(module =>
            <div className="border border-white rounded-lg h-32 w-32 text-sm p-4 mr-4 pointer-events-auto">
              <div className="text-center">
                <div>Moduł</div>
                <div className="font-medium text-ellipsis overflow-hidden text-nowrap">{moduleTranslation.getCurrentTranslation(module)?.name}</div>
                <div>
                  {annually ? `${(module?.priceAnnually || 0).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN rocznie` : `${(module?.price || 0).toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })} PLN miesięcznie`}
                </div>
              </div>
              <div className="text-end">
                <FontAwesomeIcon icon={faTimes} onClick={() => disableModule(module.key)} className="cursor-pointer" />
              </div>
            </div>
          )}
          <div className="border-l border-white/25 pl-4 text-end">
            <span className="font-medium">Cena wybranego oprogramowania</span><br />
            <span className="text-5xl mr-2">{summary.toLocaleString(i18n.resolvedLanguage, { style: 'decimal', maximumFractionDigits: 2 })}</span>
            <span className="text-3xl mr-2">PLN</span>
            <span className="text-xl">{annually ? 'rocznie' : 'miesięcznie'}</span><br />
            <div className="flex items-center justify-between">
              <div>
                <FontAwesomeIcon icon={faTimes} onClick={onClose} className="relative top-2 cursor-pointer pointer-events-auto" />
              </div>
              {chosedPlan && <Button to="/apply" className="mt-4 pointer-events-auto">Jestem zainteresowany</Button>}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}


export function Pricing() {
  const [annually, setAnnually] = useState(false);
  const plans = PlansData as PricePlan[];
  const modules = ModulesData as PriceModule[];
  const [chosedPlan, setChosedPlan] = useState<string | undefined>();
  const [chosedModules, setChosedModules] = useState<string[]>([]);

  const choosePlan = (key: string) => setChosedPlan(key);
  const chooseModule = (key: string) => {
    if (chosedModules.includes(key)) {
      setChosedModules([...chosedModules.filter(m => m !== key)]);
    } else {
      setChosedModules([...chosedModules, key]);
    }
  }

  return (
    <section
      id="pricing"
      aria-label="Pricing"
      className={clsx('bg-slate-900 py-20 sm:py-32 relative')}
    >
      <PricingBanner
        chosedPlan={plans.find(e => e.key === chosedPlan)}
        chosedModules={chosedModules.map(p => modules.find(e => e.key === p)).filter(t => t !== undefined)}
        annually={annually}
        onClose={() => setChosedPlan(undefined)}
        disableModule={chooseModule}
      />
      <Container>
        <div className="md:text-center">
          <h2 className="font-display text-4xl sm:text-5xl tracking-tight text-white">
            Ceny dopasowane do{' '}
            <span className="relative whitespace-nowrap">
              <SwirlyDoodle className="absolute left-0 top-1/2 h-[1em] w-full fill-primary-400" />
              <span className="relative">Twoich potrzeb</span>
            </span>.
          </h2>
          <p className="mt-4 text-xl text-slate-400">
            Nasz cennik jest dostosowany do potrzeb ośrodków jeździeckich różnych rozmiarów.
          </p>
        </div>
        <div className="flex justify-center mt-8 gap-3">
          <button
            onClick={() => setAnnually(false)}
            className={clsx(
              'rounded-full py-2 px-4 text-center focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-secondary-200',
              !annually ? 'text-white bg-secondary-600' : 'text-white'
            )}
          >
            Miesięcznie
          </button>
          <button
            onClick={() => setAnnually(true)}
            className={clsx(
              'rounded-full py-2 px-4 text-center focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 focus:ring-offset-secondary-200',
              annually ? 'text-white bg-secondary-600' : 'text-white'
            )}
          >
            Rocznie
          </button>
        </div>
        <div>
          <p className="mt-4 text-base text-center text-slate-400">
            Zaoszczędź nawet dwa miesiące za darmo opłacając subskrypcje rocznie.
          </p>
        </div>
        <div className="-mx-4 mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-3 xl:mx-0 xl:gap-x-8">
          {plans.filter(p => !p.disabled).map((plan) => <Plan {...plan} annually={annually} onClick={() => plan.price ? choosePlan(plan.key) : undefined} active={chosedPlan === plan.key} />)}
        </div>
        <div className="text-center mt-24 mb-8">
          <h3 className="text-2xl text-secondary-500">Dobierz dodatkowe funkcjonalności, których potrzebujesz:</h3>
        </div>
        <div className="flex justify-center">
          {modules.filter(m => !m.disabled).map((module) => <Module {...module} annually={annually} onClick={() => chooseModule(module.key)} active={chosedModules.includes(module.key)} />)}
        </div>
        <p className="text-sm text-gray-300 mt-8">
          Podane ceny są cenami brutto obowiązującymi na okres zawartej subskrypcji.
        </p>
        <p className="text-sm text-gray-300 mt-2">
          Nasze oprogramowanie wciąż jest w fazie rozwoju. Funkcjonalności wchodzące w skład pakietów mogą ulec zmianie.
        </p>
        <p className="text-sm text-gray-300 mt-8">
          Oferujemy program poleceń pozwalający otrzymać jeden darmowy miesiąc w zamian za polecenie naszego oprogramowania klientowi - więcej informacji znajdziesz w regulaminie promocji.
        </p>
      </Container>
    </section>
  )
}
