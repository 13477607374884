import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaClient as AuthMetaClient } from "src/api/access/Auth";
import { MetaClient as NotificationsMetaClient } from "src/api/notifications/Notifications";
import { MetaClient as CmsMetaClient } from "src/api/cms/Cms";
import { MetaClient as StableMetaClient } from "src/api/stable/Stable";
import { MetaClient as BookingMetaClient } from "src/api/stable/Booking";
import { MetaClient as AccountancyMetaClient } from "src/api/financial/Accountancy";
import { MetaClient as PaymentsMetaClient } from "src/api/financial/Payments";
import { MetaClient as CoreMetaClient } from "src/api/core/Core";
import { ApiStatus, IConfig } from 'src/api/Base';
import { IMetaClient } from 'src/api/Interfaces';
import { Container } from 'src/components/Container';
import { ConfigurationApis, getApiBaseUrl } from 'src/config/config';
import useApiConfiguration from 'src/hooks/useApiConfiguration';

export interface AvailabilityRowProps {
  api: ApiConfiguration;
}

export interface ApiConfiguration {
  name: string;
  address: string;
  client: { new(configuration: IConfig, baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }): IMetaClient };
}

interface IApiData {
  name: string;
  version: string;
  status: ApiStatus;
  url: string;
}

const createClient = <T extends IMetaClient>(
  type: { new(configuration: IConfig, baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }): T },
  configuration: IConfig
): T => {
  return new type(configuration);
}


function AvailabilityRow(props: AvailabilityRowProps) {
  const { api } = props;
  const [apiData, setApiData] = useState<IApiData>();
  const apiConfiguration = useApiConfiguration();

  const fetchApiData = () => {
    const apiClient = createClient(props.api.client, apiConfiguration);
    apiClient.info()
      .then(response => setApiData({ name: api.name, version: response.version, status: response.status, url: api.address } as IApiData))
      .catch(_ => setApiData({ name: api.name, version: '0.0.0.0', status: ApiStatus.Offline, url: api.address } as IApiData));
  }
  useEffect(() => {
    fetchApiData();
  }, []);

  return (
    <div className="flex justify-between border-b border-gray-200 pb-2">
      <div className="text-base text-gray-700">{api.name} <span className="text-gray-200">{apiData?.version}</span></div>
      <div className="text-base text-gray-700">
        {apiData?.status === ApiStatus.Online && <span className="w-3 h-3 rounded-full inline-block bg-primary-500 mr-2"></span>}
        {apiData?.status === ApiStatus.Maintenance && <span className="w-3 h-3 rounded-full inline-block bg-amber-500 mr-2"></span>}
        {apiData?.status === ApiStatus.Blocked && <span className="w-3 h-3 rounded-full inline-block bg-rose-600 mr-2"></span>}
        {apiData?.status === ApiStatus.Offline && <span className="w-3 h-3 rounded-full inline-block bg-red-600 mr-2"></span>}
        {apiData === undefined && <span className="w-3 h-3 rounded-full inline-block bg-red-600 mr-2"></span>}
      </div>
    </div>
  )
}

export default function Availability() {
  const { i18n } = useTranslation();
  const apis: ApiConfiguration[] = [
    { name: 'Autoryzacja', address: getApiBaseUrl(ConfigurationApis.Auth), client: AuthMetaClient },
    { name: 'Rdzeń systemu', address: getApiBaseUrl(ConfigurationApis.Core), client: CoreMetaClient },
    { name: 'Powiadomienia', address: getApiBaseUrl(ConfigurationApis.Notifications), client: NotificationsMetaClient },
    { name: 'Treści', address: getApiBaseUrl(ConfigurationApis.Content), client: CmsMetaClient },
    { name: 'Zarządzanie stajnią', address: getApiBaseUrl(ConfigurationApis.Stable), client: StableMetaClient },
    { name: 'Silnik rezerwacji', address: getApiBaseUrl(ConfigurationApis.Booking), client: BookingMetaClient },
    { name: 'Księgowość', address: getApiBaseUrl(ConfigurationApis.Accountancy), client: AccountancyMetaClient },
    { name: 'Płatności', address: getApiBaseUrl(ConfigurationApis.Payments), client: PaymentsMetaClient }
  ];
  const time = useRef<Date>(new Date());

  useEffect(() => { window.scrollTo(0, 0) }, []);

  return (
    <>
      <Container className="my-16">
        <h1 className="text-3xl mb-16">Dostępność systemu</h1>
        <div className="border border-gray-200 bg-gray-50 rounded-md p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {apis?.map(api => <AvailabilityRow api={api} />)}
          </div>
        </div>
        <h2 className="text-sm text-gray-600 mt-16 text-center">Czas deklaracji dostępności systemu: {time.current.toLocaleString(i18n.resolvedLanguage)}</h2>
      </Container>
    </>
  )
}
