import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Index from './views/Index/Index';
import FrontLayout from './layouts/FrontLayout';
import RouterErrorBoundary from './components/Feedback/RouterErrorBoundary';
import SignIn from './views/Auth/SignIn';
import Availability from './views/Index/Availability';
import Apply from './views/Index/Apply';
import Page from './views/Cms/Page';
import Newsfeed from './views/Cms/Newsfeed';
import NewsfeedPost from './views/Cms/NewsfeedPost';
import HelpIndex from './views/Cms/HelpIndex';
import GalleriesIndex from './views/Cms/GalleriesIndex';
import HelpPage from './views/Cms/HelpPage';
import Gallery from './views/Cms/Gallery';
import SlimLayout from './layouts/SlimLayout';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RouterErrorBoundary />}>
      <Route element={<FrontLayout />}>
        <Route index path="/:locale?" element={<Index />} />

        <Route path="/:locale/availability" element={<Availability />} />
        <Route path="/:locale/apply" element={<Apply />} />

        <Route path="/:locale/p/:url" element={<Page />} />
        <Route path="/:locale/n" element={<Newsfeed />} />
        <Route path="/:locale/n/c/:url" element={<Newsfeed />} />
        <Route path="/:locale/n/p/:url" element={<NewsfeedPost />} />
        <Route path="/:locale/h" element={<HelpIndex />} />
        <Route path="/:locale/h/c/:url" element={<HelpIndex />} />
        <Route path="/:locale/h/p/:url" element={<HelpPage />} />
        <Route path="/:locale/g" element={<GalleriesIndex />} />
        <Route path="/:locale/g/c/:url" element={<GalleriesIndex />} />
        <Route path="/:locale/g/g/:url" element={<Gallery />} />
      </Route>
      <Route element={<SlimLayout />}>
        <Route path="/:locale/auth/sign-in" element={<SignIn />} />
      </Route>
    </Route>
  )
);
