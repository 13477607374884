import { useTranslation } from "react-i18next"
import { LinkProps } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';

const LocalizedLink = (props: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
  const { to, ...trimmedProps } = props;
  const { i18n } = useTranslation();
  const localizedTo = `/${i18n?.resolvedLanguage}/${to.toString()[0] === '/' ? to.toString().substring(1) : to.toString()}`;
  return <HashLink to={localizedTo} {...trimmedProps} />;
}

export default LocalizedLink;