import { useEffect } from 'react'
import { Button } from 'src/components/Button'
import { TextField } from 'src/components/Fields'

export default function SignIn() {
  useEffect(() => { window.scrollTo(0, 0) }, []);

  return (
    <>
      <h2 className="text-lg font-semibold text-gray-900">
        Zaloguj się
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        Wpisz poniżej dane do swojego konta.
      </p>
      <form action="#" className="mt-10 grid grid-cols-1 gap-y-8">
        <TextField
          label="Adres e-mail"
          name="email"
          type="email"
          autoComplete="email"
          placeholder='Adres e-mail'
          required
        />
        <TextField
          label="Hasło"
          name="password"
          type="password"
          autoComplete="current-password"
          placeholder='Hasło'
          required
        />
        <div>
          <Button type="submit" variant="solid" color="primary" className="w-full">
            <span>
              Zaloguj się <span aria-hidden="true">&rarr;</span>
            </span>
          </Button>
        </div>
      </form>
    </>
  )
}
