import { useEffect } from 'react';
import { Button } from 'src/components/Button'
import LocalizedLink from 'src/components/Router/LocalizedLink'
import SlimLayoutComponent from 'src/layouts/SlimLayoutComponent';

export default function Error() {
  useEffect(() => { window.scrollTo(0, 0) }, []);

  return (
    <SlimLayoutComponent>
      <div className="flex">
        <LocalizedLink to="/" aria-label="Home" className="flex">
          <img src="/images/logos/logo-full-color-transparent.webp" className="relative h-12 w-auto" alt="Redini Logo" />
        </LocalizedLink>
      </div>
      <p className="mt-20 text-sm font-medium text-gray-700">Błąd</p>
      <h1 className="mt-3 text-lg font-semibold text-gray-900">
        Wystąpił nieznany błąd
      </h1>
      <p className="mt-3 text-sm text-gray-700">
        Przepraszamy, ale nie możemy odnaleźć strony, której szukasz.
      </p>
      <Button to="/" className="mt-10">
        Wróć na stronę główną
      </Button>
    </SlimLayoutComponent>
  )
}
