import { useEffect } from 'react'
import { ApplyForm } from 'src/components/ApplyForm';
import { Container } from 'src/components/Container';

export default function Apply() {
  useEffect(() => { window.scrollTo(0, 0) }, []);

  return (
    <>
      <Container>
        <h1 className="text-4xl text-gray-800 mb-8">Zostań naszym klientem</h1>
        <h2 className="text-3xl text-gray-500 mb-16">Zarejestruj swoją stajnię</h2>
        <div className="max-w-3xl mx-auto mb-16">
          <ApplyForm />
        </div>
      </Container>
    </>
  )
}
