import { faAngleLeft } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet } from 'react-router'
import LocalizedLink from 'src/components/Router/LocalizedLink';
import usePageTracking from 'src/hooks/usePageTracking';

function HomeLink() {
  return (
    <LocalizedLink to="/" className="font-medium text-gray-400 hover:text-gray-500">
      <FontAwesomeIcon icon={faAngleLeft} className="inline w-4 mr-1" />
      Wróc na stronę główną
    </LocalizedLink>
  )
}

const SlimLayout = () => {
  usePageTracking();
  return (
    <div className="relative flex min-h-full shrink-0 justify-center md:px-12 lg:px-0">
      <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
        <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
          <LocalizedLink to="/" aria-label="Home" className="flex">
            <img src="/images/logos/logo-full-color-transparent.webp" className="relative h-12 w-auto mb-8" alt="Redini Logo" />
          </LocalizedLink>
          <HomeLink />
          <div className="pt-8">
            <Outlet />
          </div>
        </main>
      </div>
      <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover grayscale"
          src={'/images/background.webp'}
          alt="Background"
        />
        <div className="absolute inset-0 bg-primary-900 opacity-80"></div>
      </div>
    </div>
  )
}

export default SlimLayout;