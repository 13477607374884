/* eslint-disable @typescript-eslint/no-explicit-any */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from "../Utilities/classNames";
import Select from "../Form/FormSelect";
import Spinner from "../Feedback/Spinner";
import { useTranslation } from "react-i18next";
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export interface IPaginationComponentProps {
  total: number;
  pageSize: number;
  page: number;
  previousButton?: boolean;
  nextButton?: boolean;
  distance?: number;
  disabled?: boolean;
  pageSizes?: number[];
  onSetPage?: (page: number) => void;
  onSetPageSize?: (pageSize: number) => void;
}

export interface IPaginationButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const PreviousButton = (props: IPaginationButtonProps) => {
  const { onClick } = props;
  const { t } = useTranslation();
  return (
    <a
      onClick={onClick}
      className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
    >
      <span className="sr-only">{t('common.pagination.previous')}</span>
      <FontAwesomeIcon icon={faAngleLeft} className="h-5 w-5" aria-hidden="true" />
    </a>
  )
}

const NextButton = (props: IPaginationButtonProps) => {
  const { onClick } = props;
  const { t } = useTranslation();
  return (
    <a
      onClick={onClick}
      className="relative ml-3 inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
    >
      <span className="sr-only">{t('common.pagination.next')}</span>
      <FontAwesomeIcon icon={faAngleRight} className="h-5 w-5" aria-hidden="true" />
    </a>
  )
}

export interface IPaginationPageButtonProps extends IPaginationButtonProps {
  page: number;
  active?: boolean;
}

const PageButton = (props: IPaginationPageButtonProps) => {
  const { page, active, onClick } = props;
  return (
    <a
      onClick={onClick}
      aria-current={active ? "page" : undefined}
      className={classNames([
        active ? "text-white bg-primary-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0",
        "relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold"
      ])}
    >
      {page}
    </a>
  )
}


const PaginationDefaultDistance = 2;

export default (props: IPaginationComponentProps) => {
  const { total, pageSize, page, previousButton, nextButton, distance, onSetPage, onSetPageSize, pageSizes, disabled } = props;
  const pages = Math.ceil(total / pageSize);
  const start = (page - 1) * pageSize + 1;
  const end = (page) * pageSize;

  const firstPageToRender = Math.max(page - (distance || PaginationDefaultDistance), 1);
  const lastPageToRender = Math.min(page + (distance || PaginationDefaultDistance), pages);
  const pagesToRender = Array.from({ length: (lastPageToRender - firstPageToRender) + 1 }, (v, k) => k + firstPageToRender);

  const pageSizeOptions = pageSizes?.map(size => ({ label: String(size), value: String(size), id: String(size) })) ||
    [{ label: String(pageSize), value: pageSize, id: String(pageSize) }];

  return (
    <div className="flex items-center justify-between py-3" >
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="sm:flex">
          <div>
            <p className="text-sm text-gray-700 sm:pt-4 sm:pr-6">
              <span className="font-medium">{start}</span> - <span className="font-medium">{end}</span> / {" "}
              <span className="font-medium">{total}</span>
            </p>
          </div>
          <div className="w-24">
            <Select
              name="pageSize"
              value={pageSize}
              onChange={(e: any) => onSetPageSize && onSetPageSize(Number(e.target.value))}
              options={pageSizeOptions}
            />
          </div>
          <div className="pt-2 pl-5">
            {disabled && <Spinner className="w-9 h-9" />}
          </div>
        </div>
        <div className={classNames([disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"])}>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            {previousButton && page > 1 && <PreviousButton onClick={() => !disabled && onSetPage && onSetPage(1)} />}
            {pagesToRender?.map(p => <PageButton key={p} page={p} active={page === p} onClick={() => !disabled && onSetPage && onSetPage(p)} />)}
            {nextButton && page < pages && <NextButton onClick={() => !disabled && onSetPage && onSetPage(pages)} />}
          </nav>
        </div>
      </div>
    </div>
  )
}