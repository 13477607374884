import { Container } from 'src/components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faFacebookMessenger, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

export function ReferralProgram() {
  return (
    <section
      id="referralProgram"
      aria-label="What our customers are saying"
      className="sm:-mt-16 bg-slate-50 pt-32 pb-8 lg:pb-16"
    >
      <Container className="lg:flex">
        <div className="lg:flex-grow lg:max-w-xl xl:max-w-lg">
          <div className="mx-auto max-w-mx-auto max-w-7xl">
            <h2 className="leading-7 text-xl font-medium text-secondary-500">Program poleceń</h2>
            <p className="mt-2 text-4xl sm:text-5xl font-medium tracking-tight text-primary-700">Poleć nasz program,<br />zyskaj miesiąc za darmo</p>
            <p className="mt-6 text-lg leading-8 text-slate-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</p>
            <p className="mt-6 text-lg leading-8 text-slate-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</p>
            <p className="mt-6 text-lg leading-8 text-slate-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</p>
            <div className="mt-8">
              <a href="#" className="inline-flex rounded-md bg-secondary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-500">
                Regulamin programu
              </a>
            </div>
          </div>
        </div>
        <div className="lg:flex-grow">
          <div className="relative">
            <img src="/images/logos/logo-color-transparent.webp" className="mx-auto w-96 h-96 lg:my-24 left-0" />
            <FontAwesomeIcon icon={faWhatsapp} className="text-7xl absolute top-1/4 right-0 md:right-auto md:left-3/4 transform md:-translate-x-1/2 lg:-translate-x-0 -translate-y-1/2 text-emerald-500" />
            <FontAwesomeIcon icon={faFacebookMessenger} className="text-6xl absolute top-1/4 md:left-1/4 transform md:-translate-x-1/2 lg:-translate-x-full -translate-y-1/2 text-sky-500" />
            <FontAwesomeIcon icon={faFacebook} className="text-6xl absolute top-3/4 right-0 md:right-auto md:left-3/4 transform md:-translate-x-1/2 lg:-translate-x-0 -translate-y-1/2 text-primary-600" />
            <FontAwesomeIcon icon={faInstagram} className="text-6xl absolute top-3/4 md:left-1/4 transform md:-translate-x-1/2 lg:-translate-x-full -translate-y-1/2 text-secondary-500" />
          </div>
        </div>
      </Container>
    </section>
  )
}
