import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { ClientGalleriesClient, ClientGalleryCategoriesClient, Content, ContentTranslation, ProblemDetails } from "src/api/cms/Cms";
import Spinner from "src/components/Feedback/Spinner";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";

import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setBreadcrumbs } from "src/store/application/actions";
import useLocalizedNavigate from "src/hooks/useNavigate";
import { Dialog, Transition } from "@headlessui/react";
import ContentParse from "src/components/Cms/ContentParse";
import configuration, { ConfigurationApis } from "src/config/config";
import useTenant from "src/hooks/useTenant";
import { faTag, faTimes } from "@fortawesome/free-solid-svg-icons";

export interface GalleryElementComponentProps {
  gallery: Content;
}

export interface PhotoComponentProps {
  photo: Content;
}


export interface PhotoDialogComponentProps {
  photo: Content;
  open: boolean;
  setOpen: (v: boolean) => void;
}


const PhotoDialog = (props: PhotoDialogComponentProps) => {
  const { photo, open, setOpen } = props;
  const tenant = useTenant();
  const photoUrl = `${configuration.api[ConfigurationApis.Content]}/api/v1/galleries/photos/${photo.id}/download?XTenantId=${tenant}`;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-full overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon icon={faTimes} className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <img className="h-auto max-w-full rounded-lg mx-auto" src={photoUrl} alt={photo.name} title={photo.name} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const Photo = (props: PhotoComponentProps) => {
  const { photo } = props;
  const tenant = useTenant();
  const photoUrl = `${configuration.api[ConfigurationApis.Content]}/api/v1/galleries/photos/${photo.id}/download?XTenantId=${tenant}`;
  return (
    <div className="cursor-pointer">
      <img className="h-auto max-w-full rounded-lg ring-1 ring-gray-200 shadow-md hover:ring-primary-700 hover:shadow-lg" src={photoUrl} alt={photo.name} title={photo.name} />
    </div>
  );
}

export const GalleryHeader = (props: GalleryElementComponentProps) => {
  const { gallery } = props;
  const { i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(gallery);
  const plainText = translation?.content?.replace(/<[^>]+>/g, '').replaceAll(/\s/g, '') ?? "";
  return (
    <>
      <Helmet>
        <title>{translation?.meta?.title}</title>
        <meta name="title" content={translation?.meta?.title} />
        <meta name="description" content={translation?.meta?.description} />
        <meta name="keywords" content={translation?.meta?.keywords?.toLocaleLowerCase()} />
      </Helmet>
      <div className="flex border-b border-gray-200 pb-5 mt-12 mb-5 justify-between">
        <h2 className="text-3xl tracking-tight text-primary-700 sm:text-4xl">{translation?.title}</h2>
        <time dateTime={gallery.created?.toLocaleString()} className="text-white mt-3 bg-gray-400 rounded-md px-3 py-1 text-sm">
          {gallery.created?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'short', timeStyle: 'short' })}
        </time>
      </div>
      {plainText.length > 0 &&
        <div className="border-b border-gray-200 mb-5">
          <ContentParse>{translation?.content}</ContentParse>
        </div>
      }
    </>
  )
}

export const GalleryContent = (props: GalleryElementComponentProps) => {
  const { gallery } = props;
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientGalleriesClient(apiConfiguration);
  const [photos, setPhotos] = useState<Content[]>([]);
  const [selected, setSelected] = useState<Content | undefined>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiClient
      .getPhotos(
        gallery.id!,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then(response => setPhotos(response.items || []))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setDialogOpen(true);
  }, [selected]);

  return (
    <>
      {selected && <PhotoDialog photo={selected} open={isDialogOpen} setOpen={setDialogOpen} />}
      {loading && <Spinner className="h-8 mx-auto" />}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {photos.map(photo => <div onClick={() => setSelected(photo)}><Photo key={photo.id} photo={photo} /></div>)}
      </div>
    </>
  )
}

export const GalleryFooter = (props: GalleryElementComponentProps) => {
  const { gallery } = props;
  const { t, i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(gallery);
  return (
    <div className="border-y border-gray-200 py-5 mt-5 flex justify-between text-xs text-gray-400">
      <div>
        <FontAwesomeIcon icon={faTag} className="inline h-3 mb-1 mr-3" /> {translation?.meta?.keywords}
        &nbsp;
      </div>
      <div>
        {gallery.updated && <span>{t('common.fields.updated')}: {gallery.updated?.toLocaleString(i18n.resolvedLanguage)}</span>}
      </div>
    </div>
  )
}

const Gallery = () => {
  const { url } = useParams<string>();
  const { i18n } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientGalleriesClient(apiConfiguration);
  const categoriesClient = new ClientGalleryCategoriesClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const applicationDispatch = useApplicationDispatch();
  const navigate = useLocalizedNavigate();

  const [gallery, setGallery] = useState<Content | undefined>();
  const [category, setCategory] = useState<Content | undefined>();
  const translation = entityTranslation.getCurrentTranslation(gallery);

  const onError = (e: ProblemDetails) => {
    switch (e.status) {
      case 404:
        navigate('/404');
        break;
      default:
        console.error(e);
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    apiClient.findByUrl(url || "")
      .then(response => setGallery(response))
      .catch(onError);
  }, [url, i18n?.resolvedLanguage]);

  useEffect(() => {
    if (gallery?.parentId) {
      categoriesClient.findById(gallery?.parentId)
        .then(response => setCategory(response))
        .catch(onError);
    }
  }, [gallery]);

  useEffect(() => {
    const breadcrumbs = [{ label: 'cms.galleries.galleries.group', href: `/g` }];
    if (category) {
      const categoryTranslation = entityTranslation.getCurrentTranslation(category);
      breadcrumbs.push({ label: categoryTranslation?.meta?.title || "Untitled", href: `/g/c/${categoryTranslation?.url ?? category?.id}` });
    }
    if (gallery) {
      breadcrumbs.push({ label: translation?.meta?.title || "Untitled", href: `/g/g/${url}` });
    }
    applicationDispatch(setBreadcrumbs(breadcrumbs));
  }, [gallery, category]);

  if (!gallery) {
    return <Spinner className="h-24" />;
  }

  return (
    <>

      <div className="px-3 md:px-0 max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto">
        <GalleryHeader gallery={gallery} />
        <GalleryContent gallery={gallery} />
        <GalleryFooter gallery={gallery} />
      </div>
    </>
  )
}

export default Gallery;