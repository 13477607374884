import React, { FC, useEffect, useReducer, useRef } from 'react';
import { ApplicationState, initialApplicationState } from './state';
import { ApplicationActions, clearToken, clearUser, setToken, setUser } from './actions';
import { applicationReducer } from './reducer';
import _ from 'lodash';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { AuthClient } from 'src/api/access/Auth';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import useToken from 'src/hooks/useToken';
import useUser from 'src/hooks/useUser';
import signalR, { useSignalRHub } from "use-signalr-hub"

export const ApplicationContext = React.createContext<{
  state: ApplicationState;
  dispatch: React.Dispatch<ApplicationActions>;
}>({
  state: initialApplicationState,
  dispatch: () => undefined,
});

interface ApplicationProviderProps {
  children: React.ReactNode;
}

interface AuthenticationSignalRClientProps {
  fetchUser: () => void;
  signOut: () => void;
  token: string;
}

const AuthenticationSignalRClient = (props: AuthenticationSignalRClientProps) => {
  const { token, fetchUser, signOut } = props;

  const signalRCallbacks = {
    onDelete: (_id: string) => signOut(),
    onUpdate: (_id: string) => fetchUser(),
  };

  signalR.setDefaults({
    httpTransportTypeOrOptions: { accessTokenFactory: () => token },
    automaticReconnect: false
  });

  const signalRHub = useSignalRHub("http://localhost:4010/hubs/AuthHub");

  useEffect(() => {
    if (signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
    }
  }, [signalRHub]);

  return <></>;
}

const AuthenticationWatch = () => {
  const timerRef = useRef<NodeJS.Timeout>();
  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const token = useToken();
  const user = useUser();
  const appDispatch = useApplicationDispatch();

  const signOut = () => appDispatch(clearToken());

  const refreshToken = () => {
    authClient.refresh()
      .then(t => appDispatch(setToken(t)))
      .catch(() => appDispatch(clearToken()));
  }

  const fetchUser = () => {
    authClient.getUser()
      .then(u => appDispatch(setUser(u)))
      .catch(() => appDispatch(clearToken()));
  }

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);

    if (!token) {
      if (user) appDispatch(clearUser());
      return;
    }

    timerRef.current = setTimeout(refreshToken, 600000);
    fetchUser();
  }, [token]);

  return (<>
    {token && <AuthenticationSignalRClient token={token} signOut={signOut} fetchUser={fetchUser} />}
  </>);
}

const getCurrentLocalStorage = () => {
  const json = localStorage.getItem("applicationState") || "{}";
  const state = JSON.parse(json);
  return state;
}

export const ApplicationProvider: FC<ApplicationProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(
    applicationReducer,
    {
      ...initialApplicationState,
      ...getCurrentLocalStorage()
    }
  );

  useEffect(() => {
    const stateToSave = JSON.stringify(_.omit(state, ["online", "breadcrumbs", "user"]) || {});
    localStorage.setItem("applicationState", stateToSave);
  }, [state]);

  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      <AuthenticationWatch />
      {children}
    </ApplicationContext.Provider>
  );
};