import parse from 'html-react-parser';

interface ContentParseComponentProps {
  children?: string;
}

const ContentParse = (props: ContentParseComponentProps) => {
  const { children } = props;

  return (
    <>
      {parse(children ?? "")}
    </>
  );
}

export default ContentParse;