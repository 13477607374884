/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import React, { useEffect, useState } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import clsx from 'clsx'

import { Container } from 'src/components/Container'

import Data from 'src/data/FeaturesPrimary.json';
import useEntityTranslation from 'src/hooks/useEntityTranslation'
import useAutoPlay from 'src/hooks/useAutoPlay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faChevronCircleLeft, faChevronCircleRight, faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons'

export interface Feature {
  id: string;
  image: string;
  translations: FeatureTranslation[];
}

export interface FeatureTranslation {
  culture: string;
  title: string | React.ReactNode;
  slogan?: string;
  description: string;
}

export function PrimaryFeatures() {
  const features = Data as Feature[];
  const featureTranslation = useEntityTranslation<Feature, FeatureTranslation>();
  const [tabOrientation, setTabOrientation] = useState<'horizontal' | 'vertical'>('horizontal');

  const autoPlay = useAutoPlay(features.length, 2500);

  const onChange = (i: number) => {
    autoPlay.stop();
    autoPlay.setCurrentIndex(i);
  }

  useEffect(() => {
    const lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-primary-600 pb-28 pt-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
        src={'/images/background.webp'}
        alt="Background"
        width={2245}
        height={1636}
      />
      <div className="absolute inset-0 bg-primary-900 opacity-90"></div>
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-4xl sm:text-5xl tracking-tight text-white">
            To co niezbędne dla Twojego ośrodka jeździeckiego
          </h2>
          <p className="mt-6 text-xl tracking-tight text-primary-100">
            Nasze oprogramowanie pozwoli Ci na zarządzanie ośrodkiem jeździeckim w prosty i intuicyjny sposób - zaistniej dzięki nam w sieci.
          </p>
        </div>
        <TabGroup
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
          onChange={onChange}
          selectedIndex={autoPlay.currentIndex}
          onClick={() => autoPlay.stop()}
        >
          {({ selectedIndex }: any) => (
            <>
              <div className="-mx-4 flex pb-4 sm:mx-0 sm:pb-0 lg:col-span-5">
                <TabList className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal w-full">
                  <div className="text-center lg:mb-4 flex-1">
                    <FontAwesomeIcon icon={faChevronCircleUp} className="h-8 text-white opacity-50 hover:opacity-100 cursor-pointer hidden lg:block" onClick={() => autoPlay.prev()} />
                    <FontAwesomeIcon icon={faChevronCircleLeft} className="mx-3 h-8 text-white opacity-50 hover:opacity-100 cursor-pointer block lg:hidden" onClick={() => autoPlay.prev()} />
                  </div>
                  <div className="overflow-x-scroll lg:overflow-x-auto flex-grow flex lg:flex-col">
                    {features.map((feature, featureIndex) => (
                      <div
                        key={featureTranslation.getCurrentTranslation(feature)?.title}
                        className={clsx(
                          'group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 transition-all duration-300',
                          selectedIndex === featureIndex
                            ? 'bg-secondary-600 lg:bg-secondary/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                            : 'hover:bg-white/10 lg:hover:bg-white/5',
                        )}
                      >
                        <h3>
                          <Tab
                            className={clsx(
                              'font-display text-lg ui-not-focus-visible:outline-none',
                              selectedIndex === featureIndex
                                ? 'text-white'
                                : 'text-white',
                            )}
                          >
                            <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                            {featureTranslation.getCurrentTranslation(feature)?.title}
                          </Tab>
                        </h3>
                        <p
                          className={clsx(
                            'mt-2 hidden text-sm lg:block',
                            selectedIndex === featureIndex
                              ? 'text-white'
                              : 'text-primary-100 group-hover:text-white',
                          )}
                        >
                          {featureTranslation.getCurrentTranslation(feature)?.description}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="text-center lg:mt-4 flex-1">
                    <FontAwesomeIcon icon={faChevronCircleDown} className="h-8 text-white opacity-50 hover:opacity-100 cursor-pointer hidden lg:block" onClick={() => autoPlay.next()} />
                    <FontAwesomeIcon icon={faChevronCircleRight} className="mx-3 h-8 text-white opacity-50 hover:opacity-100 cursor-pointer block lg:hidden" onClick={() => autoPlay.next()} />
                  </div>
                </TabList>
              </div>
              <TabPanels className="lg:col-span-7">
                {features.map((feature) => (
                  <TabPanel key={featureTranslation.getCurrentTranslation(feature)?.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {featureTranslation.getCurrentTranslation(feature)?.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-primary-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <img
                        className="w-full"
                        src={feature.image}
                        alt={featureTranslation.getCurrentTranslation(feature)?.title}
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            </>
          )}
        </TabGroup>
      </Container>
    </section>
  )
}
