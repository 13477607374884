import { Container } from 'src/components/Container'
import { Button } from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/pro-duotone-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import LocalizedLink from './Router/LocalizedLink'
import PageLink from './Cms/PageLink'
import * as CookieConsent from "vanilla-cookieconsent";

export function Footer() {
  return (
    <footer className="bg-slate-50 mt-16">
      <Container>
        <div className="py-16">
          <div className="flex mx-auto">
            <div className="flex mx-auto">
              <img src="/images/logos/logo-full-color-transparent.webp" className="relative h-12 w-auto grayscale opacity-75" alt="Redini Logo" />
            </div>
          </div>
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <LocalizedLink to="/#features">Funkcjonalności</LocalizedLink>
              <LocalizedLink to="/#pricing">Ceny</LocalizedLink>
              <LocalizedLink to="/#contact">Kontakt</LocalizedLink>
            </div>
          </nav>
        </div>
        <div className="mx-auto grid grid-cols-1 md:grid-cols-2 gap-6 justify-between">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-6 text-sm">
            <div>
              <div className="text-xs uppercase font-semibold mb-3">Serwis</div>
              <div className="mb-2">
                <LocalizedLink to="/auth/sign-in">Zaloguj się</LocalizedLink>
              </div>
              <div className="mb-2">
                <LocalizedLink to="/apply">Zostań naszym klientem</LocalizedLink>
              </div>
              <div className="mb-2">
                <LocalizedLink to="/availability">Dostępność systemu</LocalizedLink>
              </div>
            </div>
            <div>
              <div className="text-xs uppercase font-semibold mb-3">Dokumenty</div>
              <div className="mb-2">
                <PageLink id="3b9f58a4-2682-4fe3-b395-6313e8d1cf56" />
              </div>
              <div className="mb-2">
                <PageLink id="b7cdaccb-08cc-49f8-82ad-739e1f738378" />
              </div>
              <div className="mb-2">
                <a className="cursor-pointer" onClick={() => CookieConsent.showPreferences()}>Polityka cookies</a>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-6">
            <div>
              <div>
                <div className="text-xs uppercase font-semibold mb-3">Sprzedaż</div>
                <a href="tel:48585008438">
                  <Button color="secondary" className="w-full">
                    <span>
                      <FontAwesomeIcon icon={faPhone} className="mr-2" />
                      58 500 84 38
                    </span>
                  </Button>
                </a>
              </div>
              <div>
                <a href="mailto:kontakt@redini.app">
                  <Button color="secondary" className="mt-2 w-full">
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                      kontakt@redini.app
                    </span>
                  </Button>
                </a>
              </div>
            </div>
            <div>
              <div>
                <div className="text-xs uppercase font-semibold mb-3">Wsparcie techniczne</div>
                <a href="tel:48585008439">
                  <Button color="secondary" className="w-full">
                    <span>
                      <FontAwesomeIcon icon={faPhone} className="mr-2" />
                      58 500 84 39
                    </span>
                  </Button>
                </a>
              </div>
              <div>
                <a href="mailto:support@redini.app">
                  <Button color="secondary" className="mt-2 w-full">
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                      support@redini.app
                    </span>
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
            <a href="https://www.facebook.com/redini.app/" target="_blank" className="group" aria-label="Redini on Facebook">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/redini.app/" target="_blank" className="group" aria-label="Redini on Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            &copy; {new Date().getFullYear()} Redini. Wszelkie prawa zastrzeżone.
          </p>
        </div>
      </Container>
    </footer>
  )
}
